import { BiometryTypesCustom, User } from './../types/user'
import { defineStore } from 'pinia'
import {
  CognitoUserPool,
  CognitoUser,
  AuthenticationDetails,
  CognitoUserAttribute,
  CognitoUserSession,
  CognitoRefreshToken,
} from 'amazon-cognito-identity-js'
import { toRaw } from 'vue'
import { usePlaidItemsStore } from './plaidItem'
import { useMessagesStore } from './messages'
import { useCategoriesStore } from './category'
import { useTransactionsStore } from './transaction'
import axios from 'axios'
import {
  BiometricAuth,
  BiometryType,
} from '@aparajita/capacitor-biometric-auth'
import { SecureStoragePlugin } from 'capacitor-secure-storage-plugin'

const userPool = new CognitoUserPool({
  UserPoolId: import.meta.env.VITE_USER_POOL_ID,
  ClientId: import.meta.env.VITE_USER_POOL_APP_CLIENT_ID,
})

export const useAuthStore = defineStore('auth', {
  state: () => ({
    token: null as string | null,
    refreshToken: null as string | null,
    user: null as User | null,
    interceptor: null as number | null,
    onboardingComplete: true,
    refreshTokenBiometric: null as string | null,
    usernameBiometric: null as string | null,
  }),
  getters: {
    isLoggedIn: (state) => state.token !== null,
    hasActiveSubscription: (state) => {
      return (
        state.user?.stripeSubscriptionStatus === 'active' ||
        state.user?.stripeSubscriptionStatus === 'trialing'
      )
    },
    hasExpiredSubscription: (state) => {
      return (
        state.user?.stripeSubscriptionStatus != undefined &&
        !(
          state.user?.stripeSubscriptionStatus === 'active' ||
          state.user?.stripeSubscriptionStatus === 'trialing'
        )
      )
    },
    canAddAccount: (state) => {
      const itemsStore = usePlaidItemsStore()

      const userStripePriceId = state.user?.stripePriceId

      // const accountLimits: Record<string, number> = {
      //   [import.meta.env.VITE_STRIPE_PRICE_STANDARD]: 3,
      //   [import.meta.env.VITE_STRIPE_PRICE_PRO]: 10,
      //   [import.meta.env.VITE_STRIPE_PRICE_SUPPORTER]: 20,
      // }

      let limit = 0

      if (
        userStripePriceId &&
        ['active', 'trialing'].includes(
          state.user?.stripeSubscriptionStatus ?? '',
        )
      ) {
        // limit = accountLimits[userStripePriceId]
        limit = 10
      }
      // console.log(limit, state.user?.stripeSubscriptionStatus)

      return itemsStore.items.length < limit
    },
  },
  actions: {
    async login(username: string, password: string) {
      const authenticationDetails = new AuthenticationDetails({
        Username: username,
        Password: password,
      })

      const cognitoUser = new CognitoUser({
        Username: username,
        Pool: userPool,
      })

      return await new Promise((resolve, reject) => {
        cognitoUser.authenticateUser(authenticationDetails, {
          onSuccess: async (result: CognitoUserSession) => {
            this.token = result.getIdToken().getJwtToken()
            this.refreshToken = result.getRefreshToken().getToken()
            this.setAuthHeader()
            this.setInterceptor()
            await this.getUser()
            if (this.user?.cognitoId) {
              console.log('CognitoId: ', this.user.cognitoId)
              this.amplitude.setUserId(this.user.cognitoId)
            }
            resolve(this.token)
          },

          onFailure: (err) => {
            alert(err.message || JSON.stringify(err))
            reject()
          },
        })
      })
    },
    async getUser() {
      try {
        let user = await this.axios.get(`/user`)
        this.user = new User(
          user.data.email,
          user.data.firstName,
          user.data.lastName,
          user.data.cognitoId,
          user.data.stripeCustomerId,
          user.data.stripeSubscriptionId,
          user.data.stripeSubscriptionStatus,
          user.data.stripePriceId,
        )
        await this.ionicStorage.set('user', toRaw(this.user))
        await this.ionicStorage.set('token', this.token)
        await this.ionicStorage.set('refreshToken', this.refreshToken)
        return user.data
      } catch (error) {
        console.error(error)
      }
    },
    async createCheckoutSession(priceId: string, mobile: boolean) {
      try {
        let session = await this.axios.post(`/users/createCheckoutSession`, {
          priceId,
          mobile,
        })
        return session.data
      } catch (error) {
        console.error(error)
      }
    },
    async signup(
      firstName: string,
      lastName: string,
      username: string,
      password: string,
      hearAboutBalance: string,
      agreedToTerms: boolean,
    ) {
      return await new Promise((resolve, reject) => {
        userPool.signUp(
          username,
          password,
          [
            new CognitoUserAttribute({ Name: 'given_name', Value: firstName }),
            new CognitoUserAttribute({ Name: 'family_name', Value: lastName }),
            new CognitoUserAttribute({
              Name: 'custom:hearAboutBalance',
              Value: hearAboutBalance,
            }),
            new CognitoUserAttribute({
              Name: 'custom:agreedToTerms',
              Value: agreedToTerms.toString(),
            }),
          ],
          [],
          (err, result) => {
            if (err) {
              alert(err.message || JSON.stringify(err))
              reject()
            } else {
              this.user = new User(username)
              this.onboardingComplete = false
              resolve(result)
            }
          },
        )
      })
    },
    async confirmRegistration(code: string) {
      if (!this.user) {
        alert('No user')
        return
      }
      const cognitoUser = new CognitoUser({
        Username: this.user.email,
        Pool: userPool,
      })

      return await new Promise((resolve, reject) => {
        cognitoUser.confirmRegistration(code, true, (err, result) => {
          if (err) {
            alert(err.message || JSON.stringify(err))
            reject()
          } else {
            resolve(result)
          }
        })
      })
    },
    async logout() {
      const cognitoUser = userPool.getCurrentUser()
      if (cognitoUser) {
        cognitoUser.signOut()
      }

      // Clear auth store state
      this.token = null
      this.refreshToken = null
      this.user = null
      this.onboardingComplete = true // Reset to default state

      // Clear axios default headers
      this.axios.defaults.headers.common['Authorization'] = undefined

      // Remove the interceptor
      if (this.interceptor !== null) {
        this.axios.interceptors.response.eject(this.interceptor)
        this.interceptor = null
      }

      // Clear ionic storage
      await this.ionicStorage.clear()

      // Clear other stores (you'll need to import these)
      const plaidItemsStore = usePlaidItemsStore()
      const categoriesStore = useCategoriesStore()
      const transactionsStore = useTransactionsStore()

      plaidItemsStore.$reset()
      categoriesStore.$reset()
      transactionsStore.$reset()

      // Clear any sessionStorage or localStorage items
      sessionStorage.clear()
      localStorage.clear()

      // Navigate to login page
      this.router.replace('/auth/login')
    },
    setAuthHeader() {
      this.axios.defaults.headers.common['Authorization'] = this.token
    },
    async loadRefreshToken() {
      this.refreshToken = await this.ionicStorage.get('refreshToken')
      this.user = await this.ionicStorage.get('user')
    },
    async refreshSession(from: 'normal' | 'biometric' = 'normal') {
      let cognitoUser: any
      cognitoUser = userPool.getCurrentUser()
      if (!cognitoUser) {
        if (from === 'normal') {
          if (!this.user || !this.user.email) {
            throw new Error('No user')
          }
          cognitoUser = new CognitoUser({
            Username: this.user.email,
            Pool: userPool,
          })
        } else if (from === 'biometric') {
          if (!this.usernameBiometric) {
            throw new Error('No user')
          }
          cognitoUser = new CognitoUser({
            Username: this.usernameBiometric,
            Pool: userPool,
          })
        }
      }
      if (!this.refreshToken) {
        console.error('No refresh token')
      }

      const refreshToken = new CognitoRefreshToken({
        RefreshToken:
          from === 'normal' ? this.refreshToken : this.refreshTokenBiometric,
      })
      return await new Promise((resolve, reject) => {
        cognitoUser.refreshSession(refreshToken, (err, session) => {
          if (err) {
            console.log('Error refreshing session', err)
            this.logout()
            if (from === 'biometric') {
              this.clearBiometrics()
            }
            reject(err)
          } else {
            this.token = session.getIdToken().getJwtToken()
            this.setAuthHeader()
            resolve(session)
          }
        })
      })
    },
    //   setInterceptor() {
    //     this.interceptor = this.axios.interceptors.response.use(
    //       (response) => response,
    //       (error) => {
    //         if (!error.response) {
    //           this.logout()
    //           this.router.push('/')
    //         }
    //       },
    //     )
    //   },
    // },
    setInterceptor() {
      this.interceptor = this.axios.interceptors.response.use(
        (response) => response,
        async (error) => {
          if (error) {
            if (error.response && error.response.status === 500) {
              return Promise.reject(error)
            }
            try {
              const session = await this.refreshSession()
              // Retry the original request with the new token
              return this.axios({
                ...error.config,
                headers: { Authorization: session.getIdToken().getJwtToken() },
              })
            } catch (refreshError) {
              // If refresh token is expired or any other error occurs, logout
              this.logout()
              this.router.push('/auth/login')
            }
          }
          return Promise.reject(error)
        },
      )
    },
    async checkBiometricAvailability() {
      return (await BiometricAuth.checkBiometry()).isAvailable
    },
    async getAvailableBiometricTypes(): Promise<string[]> {
      const biometryTypes = (await BiometricAuth.checkBiometry()).biometryTypes
      return biometryTypes.map((type) => BiometryTypesCustom[type])
    },
    async fetchStoredBiometricToken() {
      try {
        const { value: refreshToken } = await SecureStoragePlugin.get({
          key: 'refreshToken',
        })
        this.refreshTokenBiometric = refreshToken
        const { value: username } = await SecureStoragePlugin.get({
          key: 'username',
        })
        this.usernameBiometric = username
      } catch (e) {
        console.error('error in getting biometrics stored auth', e)
        this.refreshTokenBiometric = null
      }
    },
    async storeBiometricCredentials() {
      try {
        if (!this.user?.email || !this.refreshToken) {
          throw new Error('No user')
        }
        await SecureStoragePlugin.set({
          key: 'refreshToken',
          value: this.refreshToken,
        })
        await SecureStoragePlugin.set({
          key: 'username',
          value: this.user.email,
        })
        this.refreshTokenBiometric = this.refreshToken
      } catch (error) {
        console.error('Error storing credentials', error)
        throw error
      }
    },

    async clearBiometrics() {
      try {
        await SecureStoragePlugin.clear()
        this.refreshTokenBiometric = null
        this.usernameBiometric = null
      } catch (error) {
        console.error(error)
      }
    },

    async authenticateWithBiometrics() {
      try {
        const isBiometricAvailable = await this.checkBiometricAvailability()
        const biometricLabel =
          BiometryTypesCustom[
            (await BiometricAuth.checkBiometry()).biometryTypes[0]
          ]

        if (!isBiometricAvailable) {
          throw new Error(`${biometricLabel} is not available`)
        }
        await this.fetchStoredBiometricToken()

        if (!this.refreshTokenBiometric || !this.usernameBiometric) {
          throw new Error(`No saved ${biometricLabel} tokens`)
        }

        await BiometricAuth.authenticate({
          reason: `Login with ${biometricLabel}`,
          androidTitle: `${biometricLabel} Login`,
        })
        this.refreshToken = this.refreshTokenBiometric
        await this.refreshSession('biometric')
        await this.getUser()
      } catch (error) {
        console.error('Biometric authentication failed', error)
        throw error
      }
    },
  },
})
