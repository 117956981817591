<template>
  <ion-app>
    <ion-router-outlet />
    <balance-modal />
    <messages-controller />
  </ion-app>
</template>

<script setup lang="ts">
import { IonApp, IonRouterOutlet } from '@ionic/vue'
import { onBeforeUnmount, onMounted } from 'vue'
import { useAuthStore } from './stores/auth'
import MessagesController from './components/MessagesController.vue'
import BalanceModal from './components/BalanceModal.vue'
import { useSettingsStore } from './stores/settingsStore'

const auth = useAuthStore()
const settings = useSettingsStore()

let backgroundStartTime: number | null = null
const backgroundThreshold = 5 * 60 * 1000

const handleVisibilityChange = () => {
  if (document.visibilityState === 'hidden') {
    backgroundStartTime = Date.now()
  } else if (document.visibilityState === 'visible') {
    if (backgroundStartTime) {
      const backgroundDuration = Date.now() - backgroundStartTime
      if (backgroundDuration > backgroundThreshold) {
        window.location.reload()
      }
    }
  }
}

onMounted(async () => {
  if (settings.darkMode === true) {
    document.documentElement.classList.add('dark')
  } else {
    document.documentElement.classList.remove('dark')
  }
  auth.setInterceptor()
  if (auth.user) {
    auth.setAuthHeader()
  }
  document.addEventListener('visibilitychange', handleVisibilityChange)
})

onBeforeUnmount(() => {
  document.removeEventListener('visibilitychange', handleVisibilityChange)
})
</script>
