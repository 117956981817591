import { defineStore } from 'pinia'

export const useSettingsStore = defineStore('settings', {
  state: () => ({
    darkMode: false,
  }),
  getters: {
    isDarkMode(state): boolean {
      return state.darkMode
    },
  },
  actions: {
    async initDarkMode() {
      const storedMode = await this.ionicStorage.get('darkMode')
      this.darkMode = storedMode ?? false
    },
    async setDarkMode(mode: boolean) {
      await this.ionicStorage.set('darkMode', mode)
      this.darkMode = mode
    },
  },
})
