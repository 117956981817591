<style scoped>
ion-tab-bar {
  --background: #000000;
  padding: 12px 24px;
  border-top: 1px solid var(--ion-color-gray2);
}

ion-icon {
  width: 36px;
}

.icon {
  fill: #b6b6b6;
}

.icon-selected {
  fill: #222;
  background: #ffffff;
  padding: 1px 8px;
  border-radius: 16px;
}

ion-tab-button {
  --color: #b6b6b6;
  --color-selected: #ffffff;
  position: relative;
  overflow: visible;
  font-size: 14px;
}

ion-tab-button ion-icon,
ion-tab-button ion-label {
  transition: color 0.3s ease;
  color: var(--color);
}

ion-tab-button.tab-selected ion-icon,
ion-tab-button.tab-selected ion-label {
  color: var(--color-selected);
  font-weight: 500;
}
</style>
<template>
  <ion-page>
    <ion-tabs>
      <ion-router-outlet />
      <ion-tab-bar slot="bottom">
        <ion-tab-button
          tab="balance"
          href="/tabs/balance"
          @click="selectedTab = 'balance'"
        >
          <ion-icon
            :class="getIconClass('balance').value"
            :src="moneyIcon"
          />
          <ion-label>Budget</ion-label>
        </ion-tab-button>

        <ion-tab-button
          tab="transactions"
          href="/tabs/transactions"
          @click="selectedTab = 'transactions'"
        >
          <ion-icon
            :class="getIconClass('transactions').value"
            :src="receiptIcon"
          />
          <ion-label>Transactions</ion-label>
        </ion-tab-button>

        <ion-tab-button
          tab="accounts"
          href="/tabs/accounts"
          @click="selectedTab = 'accounts'"
        >
          <ion-icon
            :class="getIconClass('accounts').value"
            :src="walletIcon"
          />
          <ion-label>Accounts</ion-label>
        </ion-tab-button>
      </ion-tab-bar>
    </ion-tabs>
  </ion-page>
</template>

<script lang="ts" setup>
import {
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonLabel,
  IonIcon,
  IonPage,
  IonRouterOutlet,
} from '@ionic/vue'
import { listOutline, cashOutline, walletOutline } from 'ionicons/icons'
import moneyIcon from '@/assets/img/money-icon.svg'
import receiptIcon from '@/assets/img/receipt-icon.svg'
import walletIcon from '@/assets/img/wallet-icon.svg'
import { computed, ref } from 'vue'

const selectedTab = ref('balance')

const getIconClass = (tabName: string) => {
  return computed(() =>
    selectedTab.value === tabName ? 'icon-selected' : 'icon',
  )
}
</script>
