<template></template>

<script lang="ts" setup>
import { toastController } from '@ionic/vue'
import { useMessagesStore } from '@/stores/messages'
import { Message } from '@/types/Message'
import { watch } from 'vue'
import { useRouter } from 'vue-router'

const router = useRouter()

const messagesStore = useMessagesStore()
// messagesStore.$subscribe((payload) => {
//   console.log(payload)
//   // presentToast(mutation.events.newValue)
// })

watch(messagesStore.messages, (messages) => {
  console.log(messages)
  if (messages.length > 0) {
    presentToast(messages[messages.length - 1])
  }
})

const presentToast = async (message: Message): Promise<void> => {
  const toastButtons = [
    {
      text: 'Fix connection',
      role: 'info',
      handler: () => {
        router.push('/tabs/accounts')
      },
    },
  ]
  const toast = await toastController.create({
    message: message.message,
    duration: message.duration,
    position: 'bottom',
    buttons: toastButtons,
    color: 'primary',
  })

  await toast.present()
}
</script>

<style lang="scss" scoped></style>
