<template>
  <ion-page ref="pageRef">
    <ion-modal
      :is-open="isOpen"
      @ionModalDidDismiss="closeModal"
      :presenting-element="
        modalProps.useCardView === false ? undefined : pageRef
      "
    >
      <ion-header class="primary-header">
        <ion-toolbar
          :class="
            settingsStore.isDarkMode
              ? 'primary-header-dark'
              : 'primary-header-light'
          "
        >
          <ion-title>{{ modalTitle }}</ion-title>
          <ion-buttons slot="end">
            <ion-button
              @click="close"
              class="no-outline"
            >
              <ion-icon
                :icon="closeCircleOutline"
                slot="icon-only"
                color="#fff"
              />
            </ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>
      <template
        v-if="
          modalProps.useIonContent === null ||
          modalProps.useIonContent !== false
        "
      >
        <ion-content>
          <component
            :is="modalContent"
            v-bind="modalProps"
          />
        </ion-content>
      </template>
      <template v-else>
        <component
          :is="modalContent"
          v-bind="modalProps"
        />
      </template>
    </ion-modal>
  </ion-page>
</template>

<script lang="ts" setup>
import {
  IonModal,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
  IonIcon,
  IonContent,
} from '@ionic/vue'
import { closeCircleOutline } from 'ionicons/icons'
import { useModalStore } from '@/stores/modalStore'
import { storeToRefs } from 'pinia'
import { computed, ref } from 'vue'
import { useSettingsStore } from '@/stores/settingsStore'
const modalStore = useModalStore()
const settingsStore = useSettingsStore()

const { isOpen, modalContent, modalTitle, modalProps } = storeToRefs(modalStore)
const closeModal = modalStore.closeModal

const pageRef = ref(null)

const close = () => {
  console.log('closing modal ....')
  closeModal()
}
</script>

<style scoped>
.primary-header-light {
  --background: var(--ion-color-gray7);
  color: var(--ion-color-gray0);
}
.primary-header-dark {
  --background: var(--ion-color-gray0);
  color: var(--ion-color-gray7);
}
.content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
</style>
