import { BalanceTransaction } from '@/types/BalanceTransaction'
import { extractYearAndMonth, getCurrentYearAndMonth } from '@/utils'
import { defineStore } from 'pinia'
import { toRaw } from 'vue'
import { useMessagesStore } from './messages'
import { LoadingType, useLoadingStore } from './loading'
import { usePlaidItemsStore } from './plaidItem'
import { useCategoriesStore } from './category'

export const useTransactionsStore = defineStore('transactions', {
  state: () => ({
    transactions: {} as Record<string, Record<string, BalanceTransaction>>,
  }),
  getters: {
    // ENHANCEMENT - Consider passing the year and month as parameters to this getter
    getTransactionById: (state) => (transactionId: string) => {
      for (const key in state.transactions) {
        if (state.transactions[key][transactionId]) {
          return state.transactions[key][transactionId]
        }
      }
      return null
    },
    getTransactionsForYearAndMonth: (state) => (yearAndMonth: string) => {
      return state.transactions[`${yearAndMonth}`]
    },
    getMonthlyExpenses: (state) => (yearAndMonth: string) => {
      const transactions = state.transactions[`${yearAndMonth}`]
      if (!transactions) {
        return 0
      }
      const expenses = Object.values(transactions).reduce(
        (total, transaction) => {
          const amount = parseFloat(transaction.amount)
          if (transaction.amount > 0 && !transaction.pending) {
            return (total += amount)
          }
          return total
        },
        0,
      )
      return expenses
    },
    getMonthlyIncome: (state) => (yearAndMonth: string) => {
      const plaidItemsStore = usePlaidItemsStore()
      const transactions = state.transactions[`${yearAndMonth}`]
      if (!transactions) {
        return 0
      }
      const income = Object.values(transactions).reduce(
        (total, transaction) => {
          const isManualTransaction = transaction.dataProvider === 'USER'
          if (
            (isManualTransaction ||
              plaidItemsStore.isAccountIncludedInBalance(
                transaction.account_id,
              )) &&
            transaction.amount < 0 &&
            !transaction.pending
          ) {
            return plaidItemsStore.isAccountCredit(transaction.account_id)
              ? (total -= transaction.amount)
              : (total += transaction.amount)
          }
          return total
        },
        0,
      )
      return Math.abs(income)
    },
  },
  actions: {
    async loadFromDeviceStorage() {
      const loadingStore = useLoadingStore()
      loadingStore.startLoading(LoadingType.FetchAllTransactions)
      const storedTransactions = JSON.parse(
        await this.ionicStorage.get('transactions'),
      )
      if (storedTransactions) {
        this.transactions = storedTransactions
      }
      loadingStore.finishLoading(LoadingType.FetchAllTransactions)
    },
    async getTransactionsForAllItems(
      year: number,
      month: string,
      sync: boolean,
      showLoader: boolean = true,
    ) {
      const loadingStore = useLoadingStore()
      showLoader
        ? loadingStore.startLoading(LoadingType.FetchAllTransactions)
        : null
      try {
        const response = await this.axios.get(
          `/transactions/${year}/${month}/${sync}`,
        )
        this.transactions = {
          ...this.transactions,
          ...response.data.transactions,
        }
        if (response.data.errors.length > 0) {
          const messagesStore = useMessagesStore()
          messagesStore.messages.push({
            message: 'Your bank account needs updated credentials.',
            duration: 10000,
            position: 'top',
          })
        }
        await this.ionicStorage.set(
          'transactions',
          JSON.stringify(toRaw(this.transactions)),
        )
        loadingStore.finishLoading(LoadingType.FetchAllTransactions)
      } catch (error) {
        console.error(error)
        loadingStore.finishLoading(LoadingType.FetchAllTransactions)
      }
    },
    async fetchTransactionsForItem(
      itemId: string,
      year: string,
      month: string,
      sync: string,
    ) {
      try {
        const response = await this.axios.get(
          `/items/${itemId}/getTransactions/${year}/${month}/${sync}`,
        )
        this.transactions = response.data
      } catch (error) {
        console.error(error)
      }
    },
    async updateCategoriesForTransaction(
      transactionId: string,
      oldCategories: { categoryId: string; amount: number }[],
      newCategories: { categoryId: string; amount: number }[],
    ) {
      let transaction = this.getTransactionById(transactionId)
      if (!transaction) {
        throw new Error("Transaction doesn't exist")
      }

      // Calculate the total amount of the transaction
      const totalAmount = newCategories.reduce(
        (total, category) =>
          total + parseFloat((category.amount || 0).toString()),
        0,
      )

      // Check if the total amount matches the transaction amount
      if (totalAmount !== transaction.amount) {
        throw new Error(
          'The total amount of categories does not match the transaction amount.',
        )
      }

      transaction.balanceCategories = newCategories
      if (oldCategories.length > 0) {
        const categoriesStore = useCategoriesStore()
        oldCategories.forEach((category) => {
          categoriesStore.removeTransactionFromCategory(
            category.categoryId,
            transactionId,
            extractYearAndMonth(transaction?.date ?? ''),
          )
        })
      }

      newCategories.forEach((category) => {
        const categoriesStore = useCategoriesStore()
        categoriesStore.addTransactionToCategory(
          category.categoryId,
          transactionId,
          extractYearAndMonth(transaction?.date ?? ''),
          category.amount,
        )
      })

      try {
        const response = await this.axios.put(
          `/transaction/${transactionId}`,
          transaction,
        )
        this.transactions[extractYearAndMonth(transaction.date)][
          transactionId
        ] = response.data
      } catch (error) {
        console.error(error)
      }

      const currentYearAndMonth = getCurrentYearAndMonth()
      const transactionYearAndMonth = extractYearAndMonth(transaction.date)
      const isCurrentMonth = currentYearAndMonth === transactionYearAndMonth

      // Refresh all categories if the transaction is not in the current month
      if (!isCurrentMonth) {
        const categoriesStore = useCategoriesStore()
        await categoriesStore.fetchCategories()
      }
    },
    async removeCategoriesForTransaction(transactionId: string) {
      let transaction = this.getTransactionById(transactionId)
      if (!transaction) {
        throw new Error("Transaction doesn't exist")
      }

      transaction.balanceCategories = undefined

      try {
        const response = await this.axios.put(
          `/transaction/${transactionId}/deleteCategoriesForTransaction`,
          transaction,
        )
        this.transactions[extractYearAndMonth(transaction.date)][
          transactionId
        ] = response.data
      } catch (error) {
        console.error(error)
      }
    },
    async createUserTransaction(transactionData: any) {
      const response = await this.axios.post('/transactions', transactionData)
      let dateKey = extractYearAndMonth(response.data.date)
      this.transactions[dateKey] = {
        [response.data.transaction_id]: response.data,
        ...this.transactions[dateKey],
      }
      if (transactionData.categories?.length) {
        transactionData.categories.forEach(
          (category: { categoryId: string; amount: number }) => {
            const categoriesStore = useCategoriesStore()
            categoriesStore.addTransactionToCategory(
              category.categoryId,
              response.data.transaction_id,
              extractYearAndMonth(transactionData?.date ?? ''),
              category.amount,
            )
          },
        )
      }
    },
    async updateUserTransaction(transactionData: any) {
      const response = await this.axios.put(
        `/transaction/temp/${transactionData.transactionId}`,
        transactionData,
      )
      let dateKey = extractYearAndMonth(response.data.date)
      this.transactions[dateKey] = {
        [response.data.transaction_id]: response.data,
        ...this.transactions[dateKey],
      }
      // TODO: Fix this hack
      this.getTransactionsForAllItems(
        parseInt(dateKey.split('-')[0]),
        dateKey.split('-')[1],
        false,
      )
    },
    async deleteUserTransaction(transaction: BalanceTransaction) {
      let dateKey = extractYearAndMonth(transaction.date)
      await this.axios.delete(
        `/transaction/temp/${transaction.transaction_id}/${dateKey}`,
      )
      delete this.transactions[dateKey][transaction.transaction_id]
    },
  },
})
