import { format } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'

export const getCurrentYearAndMonth = (timezone: string = getTimezone()) => {
  const currentDate = new Date()
  const zonedDate = utcToZonedTime(currentDate, timezone)
  return format(zonedDate, 'yyyy-MM') // e.g. 2021-07
}

export function extractYearAndMonth(dateString: string) {
  // Use the String.prototype.slice() method to extract the year and month
  const yearAndMonth = dateString.slice(0, 7)
  return yearAndMonth
}
export function formatCurrency(amount: number) {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(amount)
}

export function formatCurrencyWithoutCents(amount: number | string) {
  if (typeof amount === 'string') {
    amount = parseFloat(amount)
  }
  amount = Math.trunc(amount)
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  }).format(amount)
}

export function getCurrencyCents(amount: number | string): string {
  if (typeof amount === 'string') {
    amount = parseFloat(amount)
  }
  const fixed = Math.abs(amount).toFixed(2)
  return fixed.split('.')[1]
}

export function capitalizeWords(input: string): string {
  return input
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}

export function formatDateForDisplay(yearAndMonth: string) {
  const [year, month] = yearAndMonth.split('-')
  const date = new Date(parseInt(year), parseInt(month) - 1)
  return date.toLocaleDateString('en-US', {
    month: 'long',
    year: 'numeric',
  })
}

export const getTimezone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone
}
